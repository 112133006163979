import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import TournamentInstructions from '../../components/tournament-instructions';

const TournamentInstructionsPage = () => (
  <Layout>
    <h1>Tournament Instructions</h1>
    <div className="stripe-hr thin" />
    <h2>How to Run your Own Tournament</h2>
    <TournamentInstructions />
    <h2>Let&apos;s Go!</h2>
    <p>
      If you read through all of that, you are ready to
      {' '}
      <Link to="/tournament/new/">
        create a tournament
      </Link>
      !
    </p>
  </Layout>
);

export default TournamentInstructionsPage;
