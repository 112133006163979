import React from 'react';
import { Link } from 'gatsby';
import VideoSection from './video-section';

const TournamentInstructions = () => (
  <div>
    <h3>Create a Tournament</h3>
    <div className="two-wide-container">
      <div>
        <ul>
          <li>
            <Link to="/tournament/new/">
              Complete new Tournament Form
            </Link>
          </li>
          <li>Share Admin Link (only with administrators)</li>
        </ul>
      </div>
      <div>
        <VideoSection
          src="https://www.youtube.com/embed/7zKEGWJsy8w?rel=0"
          title="How to Create a Code Championship Tournament"
        />
      </div>
    </div>
    <h3>Add Your Coders</h3>
    <div className="two-wide-container">
      <div>
        <ul>
          <li>
            <Link to="/tournament/home/">
              Go to Tournament Home
            </Link>
          </li>
          <li>Add Coder</li>
          <li>Click Submit</li>
          <li>Click Complete Another Form</li>
          <li>Repeat Until All Coders Have been Added</li>
        </ul>
      </div>
      <div>
        <VideoSection
          src="https://www.youtube.com/embed/OyMQN4Tt-U4?rel=0"
          title="How to Add a Player a Code Championship Tournament"
        />
      </div>
    </div>
    <h3>Get Bot Numbers from Your Coders</h3>
    <div className="two-wide-container">
      <div>
        <ul>
          <li>
            <Link to="/tournament/home/">
              Go to Tournament Home
            </Link>
          </li>
          <li>Enter bot number</li>
          <li>Click Save</li>
          <li>Repeat until all bot numbers have been added</li>
        </ul>
      </div>
      <div>
        <VideoSection
          src="https://www.youtube.com/embed/N7MYiJwr8Wc?rel=0"
          title="How to Collect Bot Numbers for a Code Championship Tournament"
        />
      </div>
    </div>
    <h3>Run Pool Play</h3>
    <div className="two-wide-container">
      <div>
        <ul>
          <li>
            <Link to="/tournament/home/">
              Go to Tournament Home
            </Link>
          </li>
          <li>Create player pools (drag and drop)</li>
          <li>Triple check all players have been entered (there&apos;s no going back)</li>
          <li>Click Set Tournament to Pool Play</li>
          <li>Decide how many players from each pool should advance (default is 2)</li>
          <li>Click Play above a matchup to see who wins</li>
          <li>Select button with winning player name, or Tie</li>
          <li>Repeat until all pool play games have been played</li>
        </ul>
      </div>
      <div>
        <VideoSection
          src="https://www.youtube.com/embed/7HDi9M_LKYA?rel=0"
          title="How to Run Pool Play for a Code Championship Tournament"
        />
      </div>
    </div>
    <h3>Run Bracket Play</h3>
    <div className="two-wide-container">
      <div>
        <ul>
          <li>
            <Link to="/tournament/home/">
              Go to Tournament Home
            </Link>
          </li>
          <li>Click Set Tournament to Bracket Play</li>
          <li>Click Play above a matchup to see who wins</li>
          <li>Select button with winning player name</li>
          <li>
            If there is a tie, look to top right for number of blocks,
            player with lower number wins tie breaker
          </li>
          <li>Repeat until all bracket play games have been played</li>
          <li>Crown the winner!</li>
        </ul>
      </div>
      <div>
        <VideoSection
          src="https://www.youtube.com/embed/VYSKPNSv5IQ?rel=0"
          title="How to Run Bracket Play for a Code Championship Tournament"
        />
      </div>
    </div>
  </div>
);

export default TournamentInstructions;
